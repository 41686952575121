const universityOverlays = {
  universityAtAlbany: {
    uaaOnePaths: [
      { lat: 42.66354164863847, lng: -73.78231224508448 },
      { lat: 42.66515895588994, lng: -73.78057417376483 },
      { lat: 42.663959785774736, lng: -73.77852496624389 },
      { lat: 42.66276059252857, lng: -73.77983924855444 },
    ],

    uaaTwoPaths: [
      { lat: 42.66094837624888, lng: -73.77422423703281 },
      { lat: 42.662229644293745, lng: -73.77285621022662 },
      { lat: 42.66041096224945, lng: -73.76972663282108 },
      { lat: 42.659298208285286, lng: -73.76824242558304 },
      { lat: 42.659083205409814, lng: -73.76844570813127 },
    ],

    uaaPolygonOptions: {
      fillColor: "#730d6e",
      fillOpacity: 0.4,
      strokeWeight: 0,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1,
    },
  },
  collegeOfSaintRose: {
    cosrPaths: [
      { lat: 42.66074866123602, lng: -73.7853652020244 },
      { lat: 42.66346883414349, lng: -73.7900892421742 },
      { lat: 42.66530779189734, lng: -73.78813864693366 },
      { lat: 42.66350328610596, lng: -73.78245060771026 },
    ],

    cosrOptions: {
      fillColor: "#fdcb0d",
      fillOpacity: 0.4,
      strokeWeight: 0,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1,
    },
  },
  washingtonPark: {
    wpPaths: [
      { lat: 42.65800931141196, lng: -73.77736340219951 },
      { lat: 42.65993205209657, lng: -73.77532200946285 },
      { lat: 42.658038556612205, lng: -73.77215759833025 },
      { lat: 42.6595065120007, lng: -73.7705866859784 },
      { lat: 42.656503967073185, lng: -73.76541924942728 },
      { lat: 42.65317790162574, lng: -73.76893571289823 },
    ],

    wpOptions: {
      fillColor: "#56ac3f",
      fillOpacity: 0.4,
      strokeWeight: 0,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1,
    },
  },
};

export default universityOverlays;
