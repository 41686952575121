import React from "react";
import {
  GoogleMap,
  useLoadScript,
  Polygon,
  Marker,
} from "@react-google-maps/api";

import LocWParking from "../assets/location-marker-with-parking.svg";

import GoogleMapsStyles from "../styles/GoogleMapsStyles";

import universityOverlays from "../components/data/universityOverlays";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 42.661495,
  lng: -73.779748,
};

const options = {
  styles: GoogleMapsStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

//POLYGON 1 - University At Albany One

const GoogleMaps = ({ properties, pois, zoomLevel }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API,
  });

  if (loadError) return "Error loading Maps";
  if (!isLoaded) return "Loading Maps";

  const {
    uaaOnePaths,
    uaaTwoPaths,
    uaaPolygonOptions,
  } = universityOverlays.universityAtAlbany;

  const { cosrPaths, cosrOptions } = universityOverlays.collegeOfSaintRose;
  const { wpPaths, wpOptions } = universityOverlays.washingtonPark;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={zoomLevel}
      center={center}
      options={options}
    >
      {pois.map((poi) => (
        <Marker
          key={poi._id}
          position={{ lat: poi.poiLocation.lat, lng: poi.poiLocation.lng }}
          icon={{
            url: poi.poiImage.asset.url,
            scaledSize: new window.google.maps.Size(66, 66),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(33, 42),
          }}
        />
      ))}
      {properties.map((property) => (
        <Marker
          key={property._id}
          position={{ lat: property.location.lat, lng: property.location.lng }}
          icon={{
            url: LocWParking,
            scaledSize: new window.google.maps.Size(28, 28),
          }}
        />
      ))}
      <Polygon paths={uaaOnePaths} options={uaaPolygonOptions} />
      <Polygon paths={uaaTwoPaths} options={uaaPolygonOptions} />
      <Polygon paths={cosrPaths} options={cosrOptions} />
      <Polygon paths={wpPaths} options={wpOptions} />
    </GoogleMap>
  );
};

export default GoogleMaps;
